// @generated by protoc-gen-connect-es v1.1.4 with parameter "target=ts"
// @generated from file autokitteh/projects/v1/svc.proto (package autokitteh.projects.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { BuildRequest, BuildResponse, CreateRequest, CreateResponse, DeleteRequest, DeleteResponse, DownloadResourcesRequest, DownloadResourcesResponse, ExportRequest, ExportResponse, GetRequest, GetResponse, LintRequest, LintResponse, ListRequest, ListResponse, SetResourcesRequest, SetResourcesResponse, UpdateRequest, UpdateResponse } from "./svc_pb.js";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service autokitteh.projects.v1.ProjectsService
 */
export const ProjectsService = {
  typeName: "autokitteh.projects.v1.ProjectsService",
  methods: {
    /**
     * @generated from rpc autokitteh.projects.v1.ProjectsService.Create
     */
    create: {
      name: "Create",
      I: CreateRequest,
      O: CreateResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc autokitteh.projects.v1.ProjectsService.Delete
     */
    delete: {
      name: "Delete",
      I: DeleteRequest,
      O: DeleteResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc autokitteh.projects.v1.ProjectsService.Get
     */
    get: {
      name: "Get",
      I: GetRequest,
      O: GetResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc autokitteh.projects.v1.ProjectsService.Update
     */
    update: {
      name: "Update",
      I: UpdateRequest,
      O: UpdateResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc autokitteh.projects.v1.ProjectsService.List
     */
    list: {
      name: "List",
      I: ListRequest,
      O: ListResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc autokitteh.projects.v1.ProjectsService.Build
     */
    build: {
      name: "Build",
      I: BuildRequest,
      O: BuildResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc autokitteh.projects.v1.ProjectsService.SetResources
     */
    setResources: {
      name: "SetResources",
      I: SetResourcesRequest,
      O: SetResourcesResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc autokitteh.projects.v1.ProjectsService.DownloadResources
     */
    downloadResources: {
      name: "DownloadResources",
      I: DownloadResourcesRequest,
      O: DownloadResourcesResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc autokitteh.projects.v1.ProjectsService.Export
     */
    export: {
      name: "Export",
      I: ExportRequest,
      O: ExportResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc autokitteh.projects.v1.ProjectsService.Lint
     */
    lint: {
      name: "Lint",
      I: LintRequest,
      O: LintResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

